html {
  font-family: "Helvetica Neue";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  color: white;
  font-size: 1rem;
  background-color: #005A9B
}

header img {
  vertical-align: middle;
  width: 1.5rem;
  /* padding: 10px; */
  /* padding-left: 30px; */
}

.header {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  align-items: center;
  text-align: center;
}

/*Page setup to separate information*/
.body-container {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 0%;
  /* margin: 2% 2%; */
  display: grid;
  grid-column-gap: 5%;
  grid-row-gap: auto;
  grid-template-columns: 25% 512px;
  grid-template-areas:
      "instructions image"
      "fileupload image"
      /* "listimages image" */
}

.body-container-instructions {
  /* width: 30rem; */
  margin-left: 5%;
  margin-right: 2%;
  grid-area: instructions;
}

.body-container-download {
  margin-left: 10%;
  grid-area: fileupload;
}

.body-container-image {
  margin-right: 5%;
  grid-area: image;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
  color: white;
  font-size: 1rem;
  background-color: #005A9B;
  text-align: center;
}

.picker {
  margin-left: 10%;
}

#retriever {
  margin-left: 10%;
  display: none;
}

#myProgress {
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 0.5rem;
  width: 15rem;
  background-color: lightgrey;
}

#myBar {
/*    display: flex;
  justify-content: center;
  align-items: center;*/
  /*vertical-align: bottom;*/
  border-radius: 0.25rem;
  width: 0%;
  height: 0.5rem;
  background-color: green;
}


